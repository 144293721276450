import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

(async () => {
  try {
    let isDev = window.location.hostname == "localhost" ? true : false;
    let isBeta = window.location.hostname.includes("beta.v4");
    let url =
      isDev || isBeta
        ? "https://beta.api.v4.controller.barracks.gg/v4/Core/FrontDiscovery"
        : "/v4/Core/FrontDiscovery";
    if ( window.location.hostname.includes("barracks.server.internal")){
      url = 'http://barracks.server.internal:5550/v4/Core/FrontDiscovery'
    }
    let response = await fetch(url);
    let availableMfs = await response.json();
    availableMfs.map((mf) => {
      registerApplication({
        name: mf.name,
        app: () =>
          System.import(
            window.location.hostname == "localhost" ? mf.devUrl : mf.prodUrl
          ),
        activeWhen: `/${mf.name.split("/")[1]}`,
      });
    });
  } catch (e) {
    console.log(e);
  }
})();

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
//console.log(applications);
const layoutEngine = constructLayoutEngine({ routes, applications });
// console.log(routes, applications);
//applications.forEach(registerApplication);

layoutEngine.activate();
start();
// console.log("single-spa started");
